import router from "../../router";

// import { mapState } from "vuex";
export default {
    namespaced: true,
    state: {
        entidadActual: {MsjNom: ""}
    },
    mutations: {
        entidadActualSet(state, entidad){
            state.entidadActual = entidad;
        }
    },
    actions: {
        nuevaEntidad(context){
            var today = new Date();
            var date = today.getFullYear() +'-'+ (today.getMonth() + 1) + '-'+ today.getDate();
            var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            var currentDateTime = date + ' ' + time;      

            var entidadNueva = {EmpId: context.rootState.empId, MsjCat1Id: 0, MsjFec: currentDateTime, MsjNom: "", MsjTxt: "", PerId: context.rootState.perId
            , MsjPer: [], MsjPerCat: [], MsjCatMsj: [], MsjLec: [], MsjLecCnd: [], MsjEnv: false, MsjFecFormatted: currentDateTime};
            context.commit('entidadActualSet', entidadNueva);
            router.push('/enviar-mensajes-edicion');
        }
    }
}