// import router from "../../router";
// import { mapState } from "vuex";
export default {
    namespaced: true,
    state: {
        entidadActual: {}
    },
    mutations: {
        entidadActualSet(state, entidad){
            state.entidadActual = entidad;
        }
    }
}