import router from "../../router";
import axios from "axios";
import funcs from "../../funcs";
// import { mapState } from "vuex";
export default {
    namespaced: true,
    state: {
        EntitiesList: [],
        EntitiesCount: 0,
        CurrentEntity: {},
        LoadingData: false
    },
    mutations: {
        CurrentEntitySet(state, Entity){ 
            state.CurrentEntity = Entity;
        },
        LoadingDataSet(state, value) {
            state.LoadingData = value;
        },
    },
    actions: {
        SelectEntity(context, Entity) {
            return new Promise((resolve) => {
                context.commit('CurrentEntitySet', Entity);
                if (Entity.TrazArt.length == 0) {
                    context.dispatch('ChildEntitiesToFront', Entity);
                }
    
                resolve(Entity);
            })
        },
        GetEntities(context, ObjParams) {
            const NumRegsPag = ObjParams.NumRegsPag;
            const NumPag = ObjParams.NumPag;
            const SortBy = ObjParams.SortBy;
            const SortDesc = ObjParams.SortDesc;

            context.commit('LoadingDataSet', true);
            
            return new Promise((resolve) => {

                const controllerParameters = {
                        Action: "GET_DATA_LIST_PAGINATED2",   
                        NumRegsPag: NumRegsPag,
                        NumPag: NumPag,
                        EmpId: context.rootState.empId,
                        DirId: context.rootState.dir.DirId
                    } ;       
    
              const AuthToken = localStorage.getItem('token');
              axios({ method: "POST", "url": context.rootState.urlRaiz + "/api/traz", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(result => {
                let items = result.data.EntsList;
                const totalItems = result.data.EntsTotCount;

                const EntitiesFront = [];
                items.forEach(element => {
                    // const TrazArtFront = []
                    // element.TrazArt.forEach(childElement => {
                    //     context.dispatch('ChildEntityToFront', childElement).then(childData=>{  TrazArtFront.push(childData)});
                    // });

                    context.dispatch('EntityToFront', element).then(data => { 
                        // data.TrazArt = TrazArtFront;
                        EntitiesFront.push(data)
                    });
                });

                // context.state.EntitiesList = EntitiesFront;
                // context.state.EntitiesCount = totalItems;
    
              if (SortBy.length === 1 && SortDesc.length === 1) {
                items = items.sort((a, b) => {
                  const sortA = a[SortBy[0]]
                  const sortB = b[SortBy[0]]
    
                  if (SortDesc[0]) {
                    if (sortA < sortB) return 1
                    if (sortA > sortB) return -1
                    return 0
                  } else {
                    if (sortA < sortB) return -1
                    if (sortA > sortB) return 1
                    return 0
                  }
                })
              }

              context.commit('LoadingDataSet', false);
              resolve({
                EntitiesFront,
                totalItems,
              })
            
            //   setTimeout(() => {
            //     context.commit('LoadingDataSet', false);
            //     resolve({
            //       items,
            //       totalItems,
            //     })
            //   }, 1000)
              });
            })
        },
        GetArtCatEntities(context, paramsObj) {
            return new Promise((resolve) => {

                const controllerParameters = {
                    Action: "NEW_TRAZ_ART",
                    EmpId: paramsObj.EmpId,
                    TrazTmp: paramsObj.TrazTmp,
                    ArtCatId: paramsObj.ArtCatId,
                    DirId: paramsObj.DirId,
                    CopTrazAnt: paramsObj.CopTrazAnt
                } ; 
          
                const AuthToken = localStorage.getItem('token');
                axios({ method: "POST", "url": context.rootState.urlRaiz + "/api/traz", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(result => {
                    const itemsTraz = result.data;
    
                    const TrazArtFront = [];
          
                    itemsTraz.forEach(element => {
                      context.dispatch('ChildEntityToFront', element).then(data=>{ 
                          TrazArtFront.push(data);
                        });
                    });

                    resolve(TrazArtFront);
                });            
            })
        },
        NewEntity(context){
            // const today = new Date();

            // const NewEntity = {};

            const controllerParameters = {
                Action: "NEW_ENTITY",
                EmpId: context.rootState.empId,
                DirId: context.rootState.dir.DirId,
                PerId: context.rootState.perIdAux
            } ; 

            const AuthToken = localStorage.getItem('token');
            axios({ method: "POST", "url": context.rootState.urlRaiz + "/api/traz", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(result => {
                const NewEntity = result.data;

                context.dispatch('EntityToFront', NewEntity).then(newData => { 
                    // context.commit('CurrentEntitySet', newData);
                    context.dispatch("SelectEntity", newData)
                    router.push('/control-trazabilidad-edicion');
                });


    
                // result.data.EntsList.forEach(element => {
                //     const TrazObj = { ArtId: element.ArtId, ArtNom: element.ArtNom, TrazArtCant: null, TrazArtLote: null, TrazArtCad: null }
                //     NewEntity.CtrlTraz.push(TrazObj);
                // });
            });            



            // const yearStr = today.getFullYear();
            // const monthStr = "0" + (today.getMonth() + 1);
            // const dayStr = "0" + today.getDate();
            // const fechaStr = yearStr + "-" + monthStr.substring(monthStr.length-2) + "-" + dayStr.substring(dayStr.length-2);

            // const NewEntity = {
            //     CtrlId: 0,
            //     CtrlTmp: fechaStr,
            //     DirId: context.rootState.dir.DirId,
            //     PerId: context.rootState.perId,
            //     CtrlObs: "",
            //     CtrlCatId: 0,
            //     PerNom: context.rootState.per.PerNom,
            //     CtrlCatNom: "", 
            //     CtrlFechaStr: fechaStr,
            //     CtrlTraz: []
            // }

            // const controllerParameters = {
            //     Action: "GET_DATA_LIST_PAGINATED",
            //     NumRegsPag: 999,
            //     NumPag: 1,
            //     EmpId: context.rootState.empId,
            //     TipoFiltro: "CONTROL_TRAZABILIDAD",
            //     CopTrazAnt: context.rootState.emp.CopTrazAnt,
            //     ArtCatId: 51
            // } ; 

            // const AuthToken = localStorage.getItem('token');
            // axios({ method: "POST", "url": context.rootState.urlRaiz + "/api/art", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(result => {
            //     result.data.EntsList.forEach(element => {
            //         const TrazObj = { ArtId: element.ArtId, ArtNom: element.ArtNom, TrazArtCant: null, TrazArtLote: null, TrazArtCad: null }
            //         NewEntity.CtrlTraz.push(TrazObj);
            //     });
            // });            

            // context.commit('CurrentEntitySet', NewEntity);
            // router.push('/control-trazabilidad-edicion');
        },
        EntityToFront(context, sourceEntity) {
            return new Promise((resolve) => {
                // const nom_sal = funcs.func_prueba("Roberto");
                // alert(nom_sal);

                // const FechaJs = new Date(sourceEntity.TrazTmp);

                // const yearStr = FechaJs.getFullYear();
                // const monthStr0 = "0" + (FechaJs.getMonth() + 1);
                // const monthStr = monthStr0.substring(monthStr0.length-2);
                // const dayStr0 = "0" + FechaJs.getDate();
                // const dayStr = dayStr0.substring(dayStr0.length-2);
                // const hourStr0 = "0" + FechaJs.getHours();
                // const hourStr = hourStr0.substring(hourStr0.length-2);
                // const minuteStr0 = "0" + FechaJs.getMinutes();
                // const minuteStr = minuteStr0.substring(minuteStr0.length-2);
    
                // const dateStr = dayStr + "/" + monthStr + "/" + yearStr;
                // const timeStr = hourStr + ":" + minuteStr;
                // const dateTimeStr = dateStr + " " + timeStr;

                const dateTimeStr = funcs.UtcDateTimeToStr(sourceEntity.TrazTmp, "datetime");
//                const dateStr = funcs.UtcDateTimeToStr(sourceEntity.TrazTmp, "date");
                const timeStr = funcs.UtcDateTimeToStr(sourceEntity.TrazTmp, "time");

                // alert(sourceEntity.TrazTmp);
                // alert(dateTimeStr);
                // alert(timeStr);

                 const ArtCatList = [];
                 let ArtCatStr = "";
                 const TrazArtFront = [];
                 sourceEntity.TrazArtCat.forEach(elem => {
                    if (ArtCatStr != "") {
                        ArtCatStr = ArtCatStr + ", ";
                    }
                    ArtCatStr = ArtCatStr + elem.ArtCat.ArtCatNom;
                 })

                // sourceEntity.TrazArt.forEach(childElement => {
                //     context.dispatch('ChildEntityToFront', childElement).then(childData=>{  TrazArtFront.push(childData)});

                    // childElement.Art.ArtCatArt.forEach(artCatArtElement => {

                        // const artCatArtElement = childElement.Art.ArtCatArt[0]
                        // const artCatListFound = ArtCatList.find(artCatListElement => artCatListElement.ArtCatId === artCatArtElement.ArtCatId)
                        // if (artCatListFound == undefined) {
                        //     ArtCatList.push(artCatArtElement);
                        //     if (ArtCatStr != "") {
                        //         ArtCatStr = ArtCatStr + ", ";
                        //     }
                        //     ArtCatStr = ArtCatStr + artCatArtElement.ArtCat.ArtCatNom;
                        // }

                    // });

                // });

                const targetEntity = {TrazId: sourceEntity.TrazId, TrazTmp: sourceEntity.TrazTmp, TrazTmpStr: dateTimeStr, TrazFecha: sourceEntity.TrazTmp.substr(0, 10),
                    TrazHora: timeStr, DirId: sourceEntity.DirId, PerId: sourceEntity.PerId, ArtCatId: sourceEntity.ArtCatId, TrazObs: sourceEntity.TrazObs, 
                    TrazArtCat: [], TrazArt: TrazArtFront, _Dir: sourceEntity.Dir, _Per: sourceEntity.Per, _ArtCatStr: ArtCatStr, _ArtCatList: ArtCatList, 
                    _ArtCat: sourceEntity.ArtCat, _TrazArtCat: sourceEntity.TrazArtCat, _TrazArt: sourceEntity.TrazArt}

                resolve(targetEntity);
            })
        },
        ChildEntitiesToFront(context, sourceEntity) {
            return new Promise((resolve) => {
                // const ArtCatList = [];
                // const ArtCatStr = "";
                const TrazArtFront = [];
                sourceEntity._TrazArt.forEach(childElement => {
                    context.dispatch('ChildEntityToFront', childElement).then(childData=>{  TrazArtFront.push(childData)});
                    // childElement.Art.ArtCatArt.forEach(artCatArtElement => {

                        // const artCatArtElement = childElement.Art.ArtCatArt[0]
                        // const artCatListFound = ArtCatList.find(artCatListElement => artCatListElement.ArtCatId === artCatArtElement.ArtCatId)
                        // if (artCatListFound == undefined) {
                        //     ArtCatList.push(artCatArtElement);
                        //     if (ArtCatStr != "") {
                        //         ArtCatStr = ArtCatStr + ", ";
                        //     }
                        //     ArtCatStr = ArtCatStr + artCatArtElement.ArtCat.ArtCatNom;
                        // }

                    // });
                });

                sourceEntity.TrazArt = TrazArtFront;
                
                const TrazArtCatFront = [];
                sourceEntity._TrazArtCat.forEach(childElement => {
                    context.dispatch('TrazArtCatToFront', childElement).then(childData => { TrazArtCatFront.push(childData) });
                });

                sourceEntity.TrazArtCat = TrazArtCatFront;

                resolve(TrazArtFront);
            })
        },
        ChildEntityToFront(context, sourceEntity) {
            return new Promise((resolve) => {
    
                const targetEntity = {TrazArtId: sourceEntity.TrazArtId, TrazId: sourceEntity.TrazId, ArtId: sourceEntity.ArtId, 
                    TrazArtCant: sourceEntity.TrazArtCant, TrazArtLote: sourceEntity.TrazArtLote, TrazArtCad: sourceEntity.TrazArtCad, 
                    _Art: sourceEntity.Art, _Traz: sourceEntity.Traz}

                if (sourceEntity.TrazArtCad != null) {
                    targetEntity.TrazArtCad = sourceEntity.TrazArtCad.substr(0, 10);
                }

                resolve(targetEntity);
            })
        },
        TrazArtCatToFront(context, sourceEntity) {
            return new Promise((resolve) => {
    
                const targetEntity = {TrazArtCatId: sourceEntity.TrazArtCatId, TrazId: sourceEntity.TrazId, ArtCatId: sourceEntity.ArtCatId, 
                    _ArtCat: sourceEntity.ArtCat, _Traz: sourceEntity.Traz}

                resolve(targetEntity);
            })
        }    
    }
}