export default {
    UtcDateTimeToStr: function(DateTimeString, StringType) {
        var DateJs;
        if (typeof(DateTimeString) == "string") {
            var Ano = Number(DateTimeString.substring(0, 4));
            var Mes = Number(DateTimeString.substring(5, 7));
            var Dia = Number(DateTimeString.substring(8, 10));
            var Horas = Number(DateTimeString.substring(11, 13));
            var Minutos = Number(DateTimeString.substring(14, 16));
            var Segundos = Number(DateTimeString.substring(17, 19));
    
            if (Ano.isNaN || Mes.isNan || Dia.isNan || Horas.isNaN || Minutos.isNaN || Segundos.isNaN) {
                DateJs = new Date(DateTimeString);
            }
            else {
                Mes = Mes - 1;
                DateJs = new Date(Ano, Mes, Dia, Horas, Minutos, Segundos);
            }
        }
        else {
            DateJs = new Date(DateTimeString);
        }

        // var DateJs = new Date(Date.UTC(Ano, Mes, Dia, Horas, Minutos, Segundos))
        // var DateJs = new Date(Ano, Mes, Dia, Horas, Minutos, Segundos);

//        var DateJs = new Date(DateTimeString);

        var yearStr = DateJs.getFullYear();
        var monthStr0 = "0" + (DateJs.getMonth() + 1);
        var monthStr = monthStr0.substring(monthStr0.length-2);
        var dayStr0 = "0" + DateJs.getDate();
        var dayStr = dayStr0.substring(dayStr0.length-2);
        var hourStr0 = "0" + DateJs.getHours();
        var hourStr = hourStr0.substring(hourStr0.length-2);
        var minuteStr0 = "0" + DateJs.getMinutes();
        var minuteStr = minuteStr0.substring(minuteStr0.length-2);

        var dateStr = dayStr + "/" + monthStr + "/" + yearStr;
        var timeStr = hourStr + ":" + minuteStr;
        var dateTimeStr = dateStr + " " + timeStr;

        var ValRet = "";

        if (StringType == "date") {
            ValRet = dateStr;
        }
        else if (StringType == "time") {
            ValRet = timeStr;
        }
        else if (StringType == "datetime") {
            ValRet = dateTimeStr;
        }

        return ValRet;
    },

    formatDate (value, addTime = false) 
    {
        if(value == null) { return ''; }
        
        try
        {
            const date = new Date(value);
            const dateString = String(date.getDate()).padStart(2, '0') + '/' + String(date.getMonth() + 1).padStart(2, '0')  + '/' + date.getFullYear();
            
            if (addTime)
            {
                const timeString = String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0');
                return dateString + ' ' + timeString;
            }
            return dateString;
        }
        catch
        {
            return '';
        }
    },

    
    getEstadoValidacionName(value)
    {
        const estados = [
            { label: 'Abierto', value: 1 },
            { label: 'Pendiente Ok Comercial', value: 2 },
            { label: 'Pendiente Ok Tecnico', value: 3 },
            { label: 'Finalizado', value: 4 }
        ];

        const estado = estados.find(f => f.value === value);
        if (estado == null) return '';
        return estado.label;
    }
}
