import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router'
import MensajesEnviados from './modules/MensajesEnviados'
import MensajesRecibidos from './modules/MensajesRecibidos'
import DocumentosFirmar from './modules/DocumentosFirmar'
import Ctrls from './modules/Ctrls'
import Traz from './modules/Traz'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    empId: 0,
    usuario: '',
    nombreUsuario: '',
    usuarioAux: '',
    nombreUsuarioAux: '',
    perId: 0,
    per: {},
    perIdAux: 0,
    perAux: {},
    emp: {},
    esDir: false,
    dirId: 0,
    dir: {},
    esEmpleado: false,
    esTienda: false,
    esDepartamento: false,
    esPartner: false,
    esProveedor: false,
    esRRHH: false,
    esDirector: false,
    esJefa: false,
    esDirDep: false,
    esDirEje: false,
    esDirGral: false,
    esResZona: false,
    esPerOficina: false,
    esTiendaCliente: false,
    esPerMantenimiento: false,
    desarrollo: false,
    local: false,
    tituloBarra: 'INICIO',
    urlRaiz: 'https://gexapi.azurewebsites.net',
    //urlRaiz: 'https://extranet.flamenandco.es:25852',
    urlRaizTpv: 'http://localhost:5000/capturar_peso',
    numMsjPtesLeer: 0,
    look: {},
    exigiendoCambioPassword: false,
    lookList: [
      {empId: 1, nombre: 'rincon', Titulo: 'El Rincón', logoMini: 'LogoElRinconMini.png', logoGrande: 'LogoElRinconGrande.png', colorFondoBarra: 'black', colorTextoCorporativo: 'yellow', dark: true, textoBienvenida1: 'Bienvenidos a la extranet de', textoBienvenida2: 'Frutos Secos el Rincón', posBadgetLogoX: 20, posBadgetLogoY: 25, colorBadgetLogo: 'red darken-4'},
      {empId: 2, nombre: 'conveniencia', Titulo: 'El Rincón', logoMini: 'LogoElRinconMini.png', logoGrande: 'LogoElRinconGrande.png', colorFondoBarra: 'black', colorTextoCorporativo: 'yellow', dark: true, textoBienvenida1: 'Bienvenidos a la extranet de', textoBienvenida2: 'Frutos Secos el Rincón', posBadgetLogoX: 20, posBadgetLogoY: 25, colorBadgetLogo: 'red darken-4'},
      {empId: 3, nombre: 'franquicias', Titulo: 'El Rincón', logoMini: 'LogoElRinconMini.png', logoGrande: 'LogoElRinconGrande.png', colorFondoBarra: 'black', colorTextoCorporativo: 'yellow', dark: true, textoBienvenida1: 'Bienvenidos a la extranet de', textoBienvenida2: 'Frutos Secos el Rincón', posBadgetLogoX: 20, posBadgetLogoY: 25, colorBadgetLogo: 'red darken-4'},
      {empId: 4, nombre: 'tostados', Titulo: 'Tostados de Calidad', logoMini: 'LogoTostadosMini.png', logoGrande: 'LogoTostadosGrande.png', colorFondoBarra: 'grey lighten-4', colorTextoCorporativo: 'orange darken-4', dark:false, textoBienvenida1: 'Bienvenidos a la extranet de', textoBienvenida2: 'Tostados de Calidad', posBadgetLogoX: 9, posBadgetLogoY: 25, colorBadgetLogo: 'red darken-4'},
      {empId: 6, nombre: 'obrador', Titulo: 'Obrador El Artesano', logoMini: 'LogoObradorMini.png', logoGrande: 'LogoObradorGrande.png', colorFondoBarra: 'grey lighten-4', colorTextoCorporativo: 'brown darken-4', dark:false, textoBienvenida1: 'Bienvenidos a la extranet de', textoBienvenida2: 'Obrador El Artesano', posBadgetLogoX: 9, posBadgetLogoY: 25, colorBadgetLogo: 'red darken-4'},
      {empId: 8, nombre: 'martin', Titulo: 'Martín Martín', logoMini: 'LogoMartinMini.png', logoGrande: 'LogoMartinGrande.png', colorFondoBarra: 'grey lighten-4', colorTextoCorporativo: 'green darken-4', dark:false, textoBienvenida1: 'Bienvenidos a la extranet de', textoBienvenida2: 'Martín Martín', posBadgetLogoX: 20, posBadgetLogoY: 25, colorBadgetLogo: 'green'},
      {empId: 10, nombre: 'flamenandco', Titulo: 'FlamenAndCo', logoMini: 'LogoFlamenandCoMini.png', logoGrande: 'LogoFlamenandCoGrande.png', colorFondoBarra: 'grey lighten-4', colorTextoCorporativo: 'grey darken-4', dark:false, textoBienvenida1: 'Bienvenidos a la extranet de', textoBienvenida2: 'Grupo Flamen&Co', posBadgetLogoX: 20, posBadgetLogoY: 25, colorBadgetLogo: 'red darken-4'},
      {empId: 50, nombre: 'tahona', Titulo: 'Tahona Goyesca', logoMini: 'LogoTahonaMini.png', logoGrande: 'LogoTahonaGrande.png', colorFondoBarra: 'grey lighten-4', colorTextoCorporativo: 'red darken-4', dark:false, textoBienvenida1: 'Tahona Goyesca', textoBienvenida2: 'Panadería Artesana', posBadgetLogoX: 20, posBadgetLogoY: 25, colorBadgetLogo: 'red darken-4'}
    ]
  },
  mutations: {
    empIdSet(state, empId){
      state.empId = empId;
    },
    usuarioSet(state, usr){
      state.usuario = usr;
    },
    nombreUsuarioSet(state, nomUsr)
    {
      state.nombreUsuario = nomUsr;
    },
    usuarioAuxSet(state, usr){
      state.usuarioAux = usr;
    },
    nombreUsuarioAuxSet(state, nomUsr)
    {
      state.nombreUsuarioAux = nomUsr;
    },
    perIdSet(state, perId){
      state.perId = perId;
    },
    perSet(state, per){
      state.per = per;
      if (per == null){
        state.esDir = false;
        state.dirId = 0;
        state.dir = {};
        state.esEmpleado = false;
        state.esTienda = false;
        state.esDepartamento = false;
        state.esPartner = false;
        state.esProveedor = false;
        state.esRRHH = false;
        state.esDirector = false;
        state.esJefa = false;
        state.esDirDep = false;
        state.esDirEje = false;
        state.esDirGral = false;
        state.esResZona = false;
        state.esPerOficina = false;
        state.esTiendaCliente = false;
        state.esPerMantenimiento = false;
      }
      else{
        if (per.Dir != null && per.Dir.length > 0){
          state.esDir = true;
          state.dirId = per.Dir[0].DirId;
          state.dir = per.Dir[0];
          }
        else{
          state.esDir = false;
          state.dirId = 0;
          state.dir = {};
        }
        if (state.per.PerCatPer == null) {
          state.esEmpleado = false;
          state.esTienda = false;
          state.esDepartamento = false;
          state.esPartner = false;
          state.esProveedor = false;
          state.esRRHH = false;
          state.esDirector = false;
          state.esJefa = false;
          state.esDirDep = false;
          state.esDirEje = false;
          state.esDirGral = false;
          state.esResZona = false;
          state.esPerOficina = false;
          state.esTiendaCliente = false;
          state.esPerMantenimiento = false;
        }
        else {
          state.esEmpleado = state.per.PerCatLevels.find(x=>x.PerCatId == 101)!=null;  // Provisional. Luego buscar valor en apl
          state.esTienda = state.per.PerCatLevels.find(x=>x.PerCatId == 102)!=null;  // Provisional. Luego buscar valor en apl
          state.esDepartamento = state.per.PerCatLevels.find(x=>x.PerCatId == 103)!=null;  // Provisional. Luego buscar valor en apl
          state.esPartner = state.per.PerCatLevels.find(x=>x.PerCatId == 107)!=null;  // Provisional. Luego buscar valor en apl
          state.esProveedor = state.per.PerCatLevels.find(x=>x.PerCatId == 104)!=null;  // Provisional. Luego buscar valor en apl
          state.esRRHH = state.per.PerCatLevels.find(x=>x.PerCatId == 502)!=null;  // Provisional. Luego buscar valor en apl
          state.esDirector = state.per.PerCatLevels.find(x=>x.PerCatId == 206)!=null || state.per.PerCatLevels.find(x=>x.PerCatId == 207)!=null; // Provisional. Luego buscar valor en apl
          state.esJefa = state.per.PerCatLevels.find(x=>x.PerCatId == 209)!=null;  // Provisional. Luego buscar valor en apl
          state.esDirDep = state.per.PerCatLevels.find(x=>x.PerCatId == 203)!=null;  // Provisional. Luego buscar valor en apl
          state.esDirEje = state.per.PerCatLevels.find(x=>x.PerCatId == 202)!=null;  // Provisional. Luego buscar valor en apl
          state.esDirGral = state.per.PerCatLevels.find(x=>x.PerCatId == 201)!=null;  // Provisional. Luego buscar valor en apl
          state.esResZona = state.per.PerCatLevels.find(x=>x.PerCatId == 220)!=null;  // Provisional. Luego buscar valor en apl
          state.esPerOficina = state.per.PerCatLevels.find(x=>x.PerCatId == 394)!=null;  // Provisional. Luego buscar valor en apl
          state.esTiendaCliente = state.per.PerCatLevels.find(x=>x.PerCatId == 680)!=null;  // Provisional. Luego buscar valor en apl
          state.esPerMantenimiento = state.per.PerCatLevels.find(x=>x.PerCatId == 308)!=null;  // Provisional. Luego buscar valor en apl
        }
      }
    },
    perIdAuxSet(state, perId){
      state.perIdAux = perId;
    },
    exigiendoCambioPasswordSet(state, valor) {
      state.exigiendoCambioPassword = valor;
    },
    perAuxSet(state, per){
      state.perAux = per;
      // if (per == null){
      //   state.esDir = false;
      //   state.dirId = 0;
      //   state.dir = {};
      // }
      // else{
      //   if (per.Dir != null && per.Dir.length > 0){
      //     state.esDir = true;
      //     state.dirId = per.Dir[0].DirId;
      //     state.dir = per.Dir[0];
      //     }
      //   else{
      //     state.esDir = false;
      //     state.dirId = 0;
      //     state.dir = {};
      //   }
      // }
    },
    empSet(state, emp){
      state.emp = emp;
    },
    lookSet(state, paramsObj){
      let nombreLook = paramsObj.nombreLook;
      let empIdLook = paramsObj.empId;
      if (nombreLook != null && nombreLook != ''){
        let lookFound = state.lookList.find(o => o.nombre === nombreLook);
        state.look = lookFound;
      } else if (empIdLook != null && empIdLook > 0){
        let lookFound = state.lookList.find(o => o.empId === empIdLook);
        state.look = lookFound;
      }
    },
    localSet(state, valor) {
      state.local = valor;
      if (valor == true){
        state.urlRaiz = 'http://localhost:25853';
        //state.urlRaiz = 'http://10.0.0.108:25853';

        // state.urlRaiz = 'https://localhost:44350';

        // state.urlRaiz = 'http://extranet.flamenandco.es:24853';
        // state.urlRaiz = 'https://extranet.flamenandco.es:25853';
      }
      else {
        if (state.desarrollo) {
          // state.urlRaiz = 'http://extranet.flamenandco.es:24853';
          state.urlRaiz = 'https://extranet.flamenandco.es:25853';
        }
        else {
          //if (document.location.port == '24852') { //PRO TIERNO GALVAN
          if (document.location.hostname == 'gexfront.azurewebsites.net' || document.location.hostname == 'portal.flamenandco.es') { //PRO AZURE
            state.urlRaiz = 'https://gexapi.azurewebsites.net'; //PRO AZURE
            //state.urlRaiz = 'https://extranet.flamenandco.es:24853'; //PRO TIERNO GALVAN
          }
          else {
            state.urlRaiz = 'http://extranet.flamenandco.es:23853';
          }
          //  state.urlRaiz = 'http://extranet.flamenandco.es:23853';
        }
      }
    },
    desarrolloSet(state, valor) {
      state.desarrollo = valor;
      if (valor == true){
        if (state.local) {
          state.urlRaiz = 'http://localhost:25853';
          //state.urlRaiz = 'http://10.0.0.108:25853';

          // state.urlRaiz = 'https://localhost:44350';
        }
        else {
          // state.urlRaiz = 'http://extranet.flamenandco.es:24853';
          state.urlRaiz = 'https://extranet.flamenandco.es:25853';
        }
        // state.urlRaiz = 'http://extranet.flamenandco.es:24853';
        // state.urlRaiz = 'https://extranet.flamenandco.es:25853';

      }
      else {
        //if (document.location.port == '24852') { //PRO TIERNO GALVAN
        if (document.location.hostname == 'gexfront.azurewebsites.net' || document.location.hostname == 'portal.flamenandco.es') { //PRO AZURE
          state.urlRaiz = 'https://gexapi.azurewebsites.net'; //PRO AZURE
          //state.urlRaiz = 'https://extranet.flamenandco.es:24853'; //PRO TIERNO GALVAN
        }
        else {
          state.urlRaiz = 'http://extranet.flamenandco.es:23853';
        }
        // state.urlRaiz = 'http://extranet.flamenandco.es:24853';
      }
    },
    tituloBarraSet(state, titulo) {
      state.tituloBarra = titulo;
    },
    numMsjPtesLeerSet(state, valor) {
      state.numMsjPtesLeer = valor
    }
  },
  exigiendoCambioPasswordSet(state, valor) {
    state.exigiendoCambioPassword = valor;
  },
  actions: {
    AsignarUsuario(context){
      return new Promise((resolve) => {
        var AuthToken = localStorage.getItem('token');

        if (AuthToken != null && AuthToken != '')
        {
            axios({ method: "GET", "url": context.state.urlRaiz + "/api/login/echouser", "headers": {"Authorization": AuthToken }})
            .then(result => {
              var UserNameEcho = result.data;
      
              if (UserNameEcho != null) {
                context.commit('usuarioSet', UserNameEcho)
      
                var controllerParameters = {
                  Action: 'GET_PER_DATA_FROM_USER', 
                  UserName: UserNameEcho
                }
    
                axios({ method: "POST", "url": context.state.urlRaiz + "/api/usr", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
                .then(result2 => {
                  if (result2.data.Emp.CamPassIniObl) {
                    if (!result2.data.Usr.PwdCambiada) {
                      context.commit('exigiendoCambioPasswordSet', true);
                      router.push('/cambio-password');
                      return;
                    }
                    else {
                      context.commit('exigiendoCambioPasswordSet', false);
                    }
                  }
                  
                  var FullName = result2.data.PerNom;
                  var PerId = result2.data.PerId;
                  var EmpId = result2.data.EmpId;
                  var per = result2.data;
                  var emp = result2.data.Emp;
      
                  context.commit('empIdSet', EmpId);
                  context.commit('nombreUsuarioSet', FullName);
                  context.commit('perIdSet', PerId);
                  context.commit('perSet', per);
                  context.commit('empSet', emp);
  
                  resolve();  // No se espera a la asignación de tienda porque si no tarda mucho
  
                  // var controllerParameters = {  // asignación de tiendas por fecha. Probablemente habrá que quitar y meter en un proceso independiente
                  //     Action: 'SET_PER_DIR_REL',
                  //     EmpId: EmpId,
                  // } ;
              
                  var AuthToken = localStorage.getItem('token');
                    axios({ method: "POST", "url": context.state.urlRaiz + "/api/dir", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(() => {
                      // alert('Tiendas asignadas');
                      // resolve();
                    });

                    // const currentRoute = router.currentRoute;
                    // if(currentRoute != null && currentRoute.query.redirect != null && currentRoute.query.redirect !== '')
                    // {
                    //   // Volvemos a la pagina en la que estaba el usuario antes de redirigir
                    //   router.push(currentRoute.query.redirect);
                    // }
                    // else
                    // {
                    //   router.push('/');
                  // }
                  if (!window.location.hash.startsWith('#/file'))
                  {
                    router.push('/');
                  }
                }).catch(error2 => {
                  if (error2.response.status == 401) {
                    router.push('/Login');
                  }
                  else {
                    alert(error2);
                  }
                })
              }
            })
            .catch(error => {
              if (error != 'Error: Network Error'){
                alert(error); 
              }
            })            
        }
      })
    },
    AsignarUsuarioAux(context){
      return new Promise((resolve) => {

        var AuthTokenAux = localStorage.getItem('tokenAux');

        if (AuthTokenAux != null && AuthTokenAux != '')
        {
          axios({ method: "GET", "url": context.state.urlRaiz + "/api/login/echouser", "headers": {"Authorization": AuthTokenAux }})
          .then(result => {
            var UserNameEcho = result.data;
    
            if (UserNameEcho != null) {
              context.commit('usuarioAuxSet', UserNameEcho)
    
              var controllerParameters = {
                Action: 'GET_PER_DATA_FROM_USER', 
                UserName: UserNameEcho
              }
  
              axios({ method: "POST", "url": context.state.urlRaiz + "/api/usr", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthTokenAux } })
              .then(result2 => {
    
                var FullName = result2.data.PerNom;
                var PerId = result2.data.PerId;
                var per = result2.data;
    
                context.commit('nombreUsuarioAuxSet', FullName);
                context.commit('perIdAuxSet', PerId);
                context.commit('perAuxSet', per);
  
                resolve();
                if (!window.location.hash.startsWith('#/file')) {
                  router.push('/');
                }
              }).catch(error2 =>{
                if (error2.response.status == 401) {
                  context.commit('usuarioAuxSet', null)
                  context.commit('nombreUsuarioAuxSet', '');
                  context.commit('perIdAuxSet', 0);
                  context.commit('perAuxSet', null);
  
                  // router.push('/Login');
                }
                else {
                  alert(error2);
                }
              })
            }
          })
          .catch(error => {
            if (error != 'Error: Network Error'){
              alert(error); 
            }
          })
        }    
        else {
          resolve();
        }    
      })
    },

    DesAsignarUsuario(context){
      // router.push('/');      

      localStorage.removeItem('token');
      context.commit('usuarioSet', '');
      context.commit('nombreUsuarioSet', '');

      context.commit('empIdSet', 0);
      context.commit('perIdSet', 0);
      context.commit('perSet', {});
      context.commit('empSet', {});
      context.commit('numMsjPtesLeerSet', 0);
      window.document.title = context.state.look.Titulo;
    },

    DesAsignarUsuarioAux(context){
      // router.push('/');

      localStorage.removeItem('tokenAux');
      context.commit('usuarioAuxSet', '');
      context.commit('nombreUsuarioAuxSet', '');

      context.commit('perIdAuxSet', 0);
      context.commit('perAuxSet', {});
      context.commit('numMsjPtesLeerSet', 0);
      window.document.title = context.state.look.Titulo;
    },
    GetMensajes (context) {
      this.loading = true
      return new Promise((resolve) => {
        // const { sortBy, sortDesc } = this.options

        // var filtroLectura = 'Sin leer';
        // var filtroDesdeFecha = '';
        // var filtroHastaFecha = '';

        // var filtroPerIdOri = null;
        // var filtroMsjCatId = null;
        // // if (this.filtroMsjCat != null && this.filtroMsjCat.MsjCatId != null) {
        // //   filtroMsjCatId = this.filtroMsjCat.MsjCatId;
        // // }
        // var filtroAsunto = '';

        // var NumRegsPag = 50;
        // var NumPag = 1;

        var perIdReceptor = 0;
        var perIdLector = 0;
        var tipomensajes = '';

        if (tipomensajes == 'Aux') {
          perIdReceptor = this.state.perIdAux;
          perIdLector = this.state.perId;
        }
        else {
          perIdReceptor = this.state.perId;
          if (this.state.esEmpleado) { 
            perIdLector = this.state.perId;
          }
          else {
            perIdLector = this.state.perIdAux;
          }
        }

        if (this.state.esTienda && (perIdLector == null || perIdLector == 0)) {
          return;
        }

        // var controllerParameters = {
        //     Action: 'GET_MENSAJES_RECIBIDOS',
        //     FiltroLectura: filtroLectura,
        //     FiltroDesdeFecha: new Date(filtroDesdeFecha),
        //     FiltroHastaFecha: new Date(filtroHastaFecha),
        //     FiltroPerIdOri: filtroPerIdOri,
        //     FiltroMsjCatId: filtroMsjCatId,
        //     FiltroAsunto: filtroAsunto,
        //     NumRegsPag: NumRegsPag,
        //     NumPag: NumPag,
        //     EsEmpleado: this.state.esEmpleado,
        //     PerId: perIdReceptor,
        //     PerIdAux: perIdLector 
        // };
        var controllerParameters = {
            Action: 'GET_MENSAJES_RECIBIDOS_PENDIENTES',
            PerId: perIdReceptor,
            PerIdAux: perIdLector
        };

        var AuthToken = localStorage.getItem('token');
        axios({ method: "POST", "url": this.state.urlRaiz + "/api/msj", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(result => {
              let items = [];

              const total = result.data.EntsTotCount;
              context.commit('numMsjPtesLeerSet', total);

              var textoMensajes = 'Mensajes ptes.';
              if (total == 1) {
                textoMensajes = 'Mensaje pte.'
              }

              if (total == 0) {
                window.document.title = context.state.look.Titulo;
              } else {
                window.document.title = total.toString() + ' ' + textoMensajes;
              }

            setTimeout(() => {
              this.loading = false
              resolve({
                items,
                total,
              })
            }, 1000)
          })
          .catch( error => {
            if (error.message.toUpperCase() == 'NETWORK ERROR')
            {
              if (navigator.userAgent.toUpperCase().includes('OPR/')) {  // Sólo si el navegador es Opera. Esto es provisional. Después habrá que cambiarlo por un error 401
                context.dispatch('DesAsignarUsuario');
                context.dispatch('DesAsignarUsuarioAux');
                router.push('/Login');
              }
            }
          })
      })
    },
    GetPreguntasPendientes() {
      return new Promise((resolve) => {
        const controllerParameters = {
          DirId: this.state.esTienda ? this.state.dirId : null,
        };       
        const AuthToken = localStorage.getItem('token');
        axios({ method: "POST", "url": this.state.urlRaiz + "/api/preguntas/pendientes", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
        .then(result => {
          const total = result.data;

          if (total > 0) alert(`Tienes ${total} pregunta${total > 1 ? 's' : ''} sin responder`)

          setTimeout(() => {
              resolve({
                total
              })
          }, 1000)
        })
        .catch(error => {
          if(error != null) {
              console.log("Error al cargar las preguntas pendientes");
          }
        });
      })
    },
  },
  modules: {
    MensajesEnviados,
    MensajesRecibidos,
    DocumentosFirmar,
    Ctrls,
    Traz
  },
})
