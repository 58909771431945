import router from "../../router";
import axios from "axios";
// import { mapState } from "vuex";
export default {
    namespaced: true,
    state: {
        entidadActual: {MsjNom: ""},
        entidadTrazabilidadActual: {}
    },
    mutations: {
        entidadActualSet(state, entidad){
            state.entidadActual = entidad;
        },
        entidadTrazabilidadActualSet(state, entidad){
            state.entidadTrazabilidadActual = entidad;
        }
    },
    actions: {
        nuevaEntidad(context){
            var today = new Date();

            var yearStr = today.getFullYear();
            var monthStr = "0" + (today.getMonth() + 1);
            var dayStr = "0" + today.getDate();
            var fechaStr = yearStr + "-" + monthStr.substring(monthStr.length-2) + "-" + dayStr.substring(dayStr.length-2);

            var entidadNueva = {
                CtrlId: 0,
                CtrlTmp: fechaStr,
                DirId: context.rootState.dir.DirId,
                PerId: context.rootState.perId,
                CtrlObs: "",
                CtrlCatId: 0,
                PerNom: context.rootState.per.PerNom,
                CtrlCatNom: "", 
                CtrlFechaStr: fechaStr,
                CtrlMaq: []
            }

            var controllerParameters = {
                Action: "GET_DATA_LIST_PAGINATED",
                NumRegsPag: 999,
                NumPag: 1,
                EmpId: context.rootState.empId,
                TipoFiltro: "CONTROL_TEMPERATURA"
            } ; 

            var AuthToken = localStorage.getItem('token');
            axios({ method: "POST", "url": context.rootState.urlRaiz + "/api/maq", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(result => {
                result.data.EntsList.forEach(element => {
                    var CtrlMaqObj = { MaqId: element.MaqId, MaqNom: element.MaqNom, MaqTtraRangoTxt: element.MaqTtraRangoTxt }
                    entidadNueva.CtrlMaq.push(CtrlMaqObj);
                });
            });            

            context.commit('entidadActualSet', entidadNueva);
            router.push('/control-temperatura-edicion');
        },
        nuevaEntidadTrazabilidad(context){
            var today = new Date();

            var yearStr = today.getFullYear();
            var monthStr = "0" + (today.getMonth() + 1);
            var dayStr = "0" + today.getDate();
            var fechaStr = yearStr + "-" + monthStr.substring(monthStr.length-2) + "-" + dayStr.substring(dayStr.length-2);

            var entidadNueva = {
                CtrlId: 0,
                CtrlTmp: fechaStr,
                DirId: context.rootState.dir.DirId,
                PerId: context.rootState.perId,
                CtrlObs: "",
                CtrlCatId: 0,
                PerNom: context.rootState.per.PerNom,
                CtrlCatNom: "", 
                CtrlFechaStr: fechaStr,
                CtrlTraz: []
            }

            var controllerParameters = {
                Action: "GET_DATA_LIST_PAGINATED",
                NumRegsPag: 999,
                NumPag: 1,
                EmpId: context.rootState.empId,
                TipoFiltro: "CONTROL_TRAZABILIDAD",
                CopTrazAnt: context.rootState.emp.CopTrazAnt,
                ArtCatId: 51
            } ; 

            var AuthToken = localStorage.getItem('token');
            axios({ method: "POST", "url": context.rootState.urlRaiz + "/api/art", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(result => {
                result.data.EntsList.forEach(element => {
                    var CtrlTrazObj = { ArtId: element.ArtId, ArtNom: element.ArtNom, CtrlTrazCant: null, CtrlTrazLote: null, CtrTrazCad: null }
                    entidadNueva.CtrlTraz.push(CtrlTrazObj);
                });
            });            

            context.commit('entidadTrazabilidadActualSet', entidadNueva);
            router.push('/control-trazabilidad-edicion');
        }        
    }
}