// import router from "../../router";

// import { mapState } from "vuex";
export default {
    namespaced: true,
    state: {
        entidadActual: {MsjNom: ""},
        receptorPerId: 0,
        perIdReceptor: 0,
        perIdLector: 0,
        tipoBandeja: "Pral",
        msjId: 0
    },
    mutations: {
        entidadActualSet(state, entidad){
            state.entidadActual = entidad;
        },
        receptorPerIdSet(state, PerId){
            state.receptorPerId = PerId;
        },
        perIdReceptorSet(state, PerId){
            state.perIdReceptor = PerId;
        },
        perIdLectorSet(state, PerId){
            state.perIdLector = PerId;
        },
        msjIdSet(state, MsjId){
            state.msjId = MsjId;
        }
    }
}